import { menulist } from '@/assets/utils/menu.js'
export const constantRoutes = [    
    {
        path: '/',
        component: () => import('../components/login/index.vue'),
        hidden: true
    }
];
// 动态添加路由配置
export const asyncRoutes =[]
menucreate()
function menucreate(){
    menulist.forEach(e => {
        if (e.child) {
            e.child.forEach(i => {
                asyncRoutes.push({
                    path:e.path+i.path,
                    name:i.name,
                    component: () => import(/* webpackChunkName: "[request]" */ `@/views${e.path}${i.path}.vue`),
                    meta: { title: i.menuename,roles:e.role},
                })
            });
        } else {
            asyncRoutes.push({
                path: e.path,
                name:e.name,
                component: () => import(/* webpackChunkName: "[request]" */ `@/views${e.path}/index.vue`),
                meta: { title: e.menuename,roles:e.role},
            });
        }
    });
}